import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import { tabsReducer } from '../reducers/tabReducer';
import thunk from 'redux-thunk';


const composeEnahncers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({ 
    tabs: tabsReducer

});

export const store = createStore(
    reducers, 
    composeEnahncers(
        applyMiddleware(thunk)
    )
    );