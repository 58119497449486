import { types } from "../types/types";

export const addTab = (values) => ({
    type: types.ADD_TAB,
    payload: {
        ...values
    }
})

export const activeTab = (id) => ({ 
    type: types.SET_ACTIVE_TAB,
    payload: {
        id
    }
})

export const verifyTab = (values, id) => {
    return  async (dispatch, getState) =>{
        const { tabs } = getState().tabs;
        const tab = await tabs.some((item) => item.id === id && item.open);
        
        if(tab){
            dispatch(activeTab(id))
        }else{
            dispatch(addTab({...values, open: true}));
        }
    }
}

export const removeTab = (id) => { 
    return async(dispatch, getState) =>{
        const { tabs } = getState().tabs;

        const resTabs = await tabs.filter((item) => item.id !== id);
        const filteredTab = await resTabs.filter((tab) => tab.open);

        filteredTab[0]?.id !== undefined ? dispatch(activeTab(filteredTab[0]?.id)) : dispatch(activeTab(id));
        
        dispatch(setTab(filteredTab))
    }
}

export const setTab = (tabs) => ({
    type: types.SET_DATA_TAB,
    payload: {
        tabs
    }
})