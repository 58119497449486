import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ReturnComponent = ({children}) => {
  return (
    <Box
        sx={{
        width: '100wh',
        height: '5vh',
        mt:'auto'
        }}
    >
        <Typography
            ml={14}
            sx={{
                color: '#DA70D6',
            }}
        >
            {`return (`}
        </Typography>

        {children}
        
        <Typography
            ml={14}
            mt='auto'
            sx={{
                color: '#DA70D6',
            }}
        >
            {`)`}
        </Typography>
    </Box>
  )
}
