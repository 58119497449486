import React from 'react';
import Box from '@mui/material/Box';
import { CodeScreen } from './CodeScreen';

export const Editor = () => {
  return (
      <Box
      sx={{
        width: '100%',
        height: '115vh',
        backgroundColor: '#18191A',
      }}
    >
      <CodeScreen />
    </Box>
  )
}
