import React from 'react';
import Box from '@mui/material/Box';
import { IconButtons } from './IconButtons';
import { MyFiles } from './MyFiles';

export const SideMenu = () => {
  return (
    <Box
    sx={{
      width: '100%',
      height: '115vh',
      backgroundColor: '#3A3B3C',
    }}
  >
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
      <Box gridColumn="span 2">
        <IconButtons />
      </Box>
      <Box gridColumn="span 10">
        <MyFiles />
      </Box>
    </Box>
  </Box>
  )
}
