import React from 'react'
import { TopMenu } from './AppBar'
import { Editor } from './Editor'
import { SideMenu } from './SideMenu'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { store } from '../store/store';
import { Provider } from 'react-redux';

export const Main = () => {
  return (
    <Provider store={store}>
      <Container maxWidth="xxl">
        <TopMenu />
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
          <Box gridColumn="span 2">
            <SideMenu />
          </Box>
          <Box gridColumn="span 10">
            <Editor />
          </Box>
        </Box>
      </Container>
    </Provider>
  )
}
