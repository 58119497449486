import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import JavascriptIcon from '@mui/icons-material/Javascript';
import Collapse from '@mui/material/Collapse';
import { AppFile } from './AppFile';
import { componentsData } from '../data/data';
import { verifyTab } from '../actions/tabs';
import { useDispatch } from 'react-redux';


export const ListFiles = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [openComponent, setOpenComponent] = useState(true);

    const Chevron = () => {
      return(open ? <KeyboardArrowDownIcon onClick={handleClick} /> : <KeyboardArrowRightIcon  onClick={handleClick} />)
     
    } 

    const handleClick = () => {
      open ? setOpen(false) : setOpen(true);
    }

    const ChevronComponent = () => {
        return(openComponent ? <KeyboardArrowDownIcon onClick={handleClickComponent} /> : <KeyboardArrowRightIcon  onClick={handleClickComponent} />)
       
      } 

    const handleClickComponent = () => {
        openComponent ? setOpenComponent(false) : setOpenComponent(true);
    }

    const handleAddTab = (values, id) => {
        dispatch(verifyTab(values, id));
    }

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Typography
                    sx={{ 
                        minWidth: 100, 
                        color:'#E4E6EB',
                        ml: -2,
                        '&:hover': {
                            cursor: 'pointer',
                        } 
                }} 
                variant="subtitle2" component="div"
                onClick={handleClick}
                >
                {Chevron(open)} DANIEL RG
                </Typography>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                    <ListItem
                        sx={{
                            ml: -2,
                            '&:hover': {
                                background: '#B0B3B8',
                                cursor: 'pointer',
                            }
                        }}
                    >
                {ChevronComponent(openComponent)}
                        <ListItemIcon>
                        <FolderIcon 
                            sx={{
                                color: '#A4DE02',
                            }}
                        />
                        </ListItemIcon>
                        <ListItemText
                            sx={{ml: -3}}
                            onClick={handleClickComponent}
                        >
                            components
                        </ListItemText>
                    </ListItem>
                    <Collapse in={openComponent} timeout="auto" unmountOnExit>
                        {
                            componentsData.map((value) => {
                                return(
                                    <ListItem
                                        key={value.id}
                                        sx={{
                                            '&:hover': {
                                                background: '#B0B3B8',
                                                marginLeft: 1,
                                                width: '94%',
                                                transition: 'all 0.3s ease',
                                            },
                                            cursor: 'pointer',
                                            ml: 3
                                            }}
                                            onClick={() =>handleAddTab(value, value.id)}
                                    >
                                        <ListItemIcon>
                                        <JavascriptIcon
                                            sx={{
                                            backgroundColor: '#FCE205',
                                            }}
                                            fontSize='small'
                                        />
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={{ml: -3}}
                                        >
                                            {value.title}
                                        </ListItemText>
                                    </ListItem>
                                )
                            })
                        
                        }
                    </Collapse>
                </List>
                <AppFile />
                </Collapse>
            </Grid>
        </Grid>
  </Box>
  )
}
