export const componentsData = [
    {   id: 1,
        title: 'About.js',
        content: 'about',
        path:'About'
    },
    {
        id: 2,
        title: 'Career.js',
        content: 'career',
        path:'Career'
    },
    {
        id: 3,
        title: 'Projects.js',
        content: 'projects',
        path:'Projects'
    }
];