
import { DataCareer } from '../data/DataCareer';
import { CommitComponent } from './shared/CommitComponent';
import { FunctionComponent } from './shared/FunctionComponent';
import { ImportComponent } from './shared/ImportComponent';
import { ReturnComponent } from './shared/ReturnComponent';
import Box from '@mui/material/Box';


export const Career = () => {
  return (
    <Box>
      <ImportComponent 
        component ={'React'}
        from ={`'react'`}
      />
      <ImportComponent 
        component ={'{ Exp }'}
        from ={`'daniel'`}
      />
      <FunctionComponent 
        componentName ={'Career'}
        marginTop={70}
        marginLeft={0}
      >
        <ReturnComponent>
          <CommitComponent 
            commit={'My professional career'}
            marginLeft={20}
          />
          <DataCareer />
        </ReturnComponent>
      </FunctionComponent>
    </Box>
  )
}
