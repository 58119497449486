import { types } from "../types/types";

const initialState = {
    tabs: [
        {
        id:1,
        title:"About.js",
        content:"about",
        path:'About',
        open:true
    }
],
    activeTab: 1
}


export const tabsReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.ADD_TAB:
            return {
                ...state,
                tabs: [ ...state.tabs, action.payload ],
                activeTab: action.payload.id
            }
        case types.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload.id
            }
        case types.SET_DATA_TAB:
            return {
                ...state,
                tabs: action.payload.tabs
            }
        default:
            return state;
    }
}