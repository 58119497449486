import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import insulap1 from '../assets/images/insulap1.png';
import insulap2 from '../assets/images/insulap2.png';
import insulap3 from '../assets/images/insulap3.png';
import insulap4 from '../assets/images/insulap4.png';
import insulap5 from '../assets/images/insulap5.png';
import insulap6 from '../assets/images/insulap6.png';
import insulap7 from '../assets/images/insulap7.png';
import insulap8 from '../assets/images/insulap8.png';
import insulap9 from '../assets/images/insulap9.png';

const srcset = (image, size, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

export const DataProjects = () => {
  return (
        <ImageList
            sx={{ width: 1000, height: 500,  ml:20 }}
            variant="quilted"
            cols={4}
            rowHeight={121}
        >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

const itemData = [
    {
      img: insulap1,
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: insulap2,
      title: 'Burger',
    },
    {
      img: insulap3,
      title: 'Camera',
    },
    {
      img: insulap4,
      title: 'Coffee',
      cols: 2,
    },
    {
      img: insulap5,
      title: 'Hats',
      cols: 2,
    },
    {
      img: insulap6,
      title: 'Honey',
      author: '@arwinneil',
      rows: 2,
      cols: 2,
    },
    {
      img: insulap7,
      title: 'Basketball',
    },
    {
      img: insulap8,
      title: 'Fern',
    },
    {
      img: insulap9,
      title: 'Mushrooms',
      rows: 2,
      cols: 2,
    },
  ];