
import { DataProjects } from '../data/DataProjects';
import { CommitComponent } from './shared/CommitComponent';
import { FunctionComponent } from './shared/FunctionComponent';
import { ImportComponent } from './shared/ImportComponent';
import { ReturnComponent } from './shared/ReturnComponent';
import Box from '@mui/material/Box';


export const Projects = () => {
  return (
    <Box>
    <ImportComponent 
      component ={'React'}
      from ={`'react'`}
    />
    <ImportComponent 
      component ={'{ Proj }'}
      from ={`'daniel'`}
    />
    <FunctionComponent 
      componentName ={'Projects'}
      marginTop={70}
      marginLeft={34}
    >
      <ReturnComponent>
        <CommitComponent 
          commit={'These are some of my personal projects'}
          marginLeft={20}
        />
        <DataProjects   />
      </ReturnComponent>
    </FunctionComponent>
  </Box>
  )
}
