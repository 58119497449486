import React from 'react'
import {
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
    List
} from '@mui/material';
import JavascriptIcon from '@mui/icons-material/Javascript';
export const AppFile = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
            <List>
                <ListItem
                  sx={{
                    '&:hover': {
                        background: '#B0B3B8',
                        marginLeft: -1,
                        transition: 'all 0.3s ease',
                      },
                      cursor: 'pointer',
                        ml: 1.3,
                        mt: -2,
                    }}
                >
                    <ListItemIcon>
                    <JavascriptIcon
                        sx={{
                        backgroundColor: '#FCE205',
                        }}
                        fontSize='small'
                    />
                    </ListItemIcon>
                    <ListItemText
                        sx={{ml: -3}}
                    >
                        App.js
                    </ListItemText>
                </ListItem>
            </List>
 
    </Box>
  )
}
