import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const FunctionComponent = ({children, componentName, marginLeft, marginTop}) => {
  return (
    <Box
        sx={{
        width: '100wh',
        height: '5vh',
        mt:'auto'
        }}
    >
        <Typography
        ml={10}
        sx={{
            color: '#DA70D6',
        }}
        >
        {`export`}
        </Typography>
        <Typography
            ml={18}
            mt={-3}
            sx={{
                color: '#357EC7',
            }}
        >
        {`const`}
        </Typography>
        <Typography
            ml={25}
            mt={-3}
            sx={{
                color: '#ECE5B6',
            }}
        >
        {componentName}
        </Typography>
        <Typography
            ml={marginLeft ? marginLeft : 32}
            mt={-3}
            sx={{
                color: '#FFFFFF',
            }}
        >
        {`= ( ) => {`}
        </Typography>
            {children}
        <Typography
            ml={12}
            mt={marginTop ? marginTop : 50}
            sx={{
                color: '#FFFFFF',
            }}
        >
        {`}`}
        </Typography>
    </Box>
  )
}
