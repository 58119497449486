import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import profile from '../assets/images/profile.jpg';
import Link from '@mui/material/Link';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  
export const DataAbout = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
  
  return (
    <Card sx={{maxWidth: 345, ml:20}}>
      <CardMedia
        component="img"
        height="400"
        image={profile}
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary" fontSize={14}>
          Hello! I'm Daniel Ruiz and I'm a web and mobile developer passionate about new technologies and focused on creating solutions. 
          I've 6 years of professional experience.
        </Typography>
      </CardContent>
      <CardActions 
        disableSpacing 
        sx={{
          mt: -3,
          mb: -1,
        }}
      >
        <Link 
          href="https://www.linkedin.com/in/daniel-ruiz-93b1b3134/"
          >
          <IconButton aria-label="Go to my linkedin">
            <LinkedInIcon />
          </IconButton>
        </Link>
        <Link 
          href="https://github.com/danieRG"
          >
          <IconButton aria-label="share">
            <GitHubIcon />
          </IconButton>
        </Link>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph variant="body2" >Skills:</Typography>
          <Typography
            paragraph 
            variant="body2" 
            fontSize={12}
            sx={{
              mt: -2,
            }}
          >
              I've experience as a team leader and applying SCRUM methodology
          </Typography>
          <Typography paragraph variant="body2">Technologies:</Typography>
          <Typography 
            paragraph 
            variant="body2" 
            fontSize={12}
            sx={{
              mt: -2,
            }}
          >
              React JS | React Native | Node JS | TypeScript | GraphQL| MongoDB | MySQL
          </Typography>
          <Typography 
            paragraph 
            variant="body2"
            sx={{
              mt: -1,
            }}
          >
            Contact:
          </Typography>
          <Typography paragraph variant="body2" fontSize={12}
            sx={{
              mt: -2,
            }}
          >
              ruizg92@gmail.com | +52 963-133-20-78
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}
