import React ,{useState}from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import JavascriptIcon from '@mui/icons-material/Javascript';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { activeTab } from '../actions/tabs';

export const OpenEditors = () => {

  const dispatch = useDispatch();
  const { tabs } = useSelector(state => state.tabs);
  const [open, setOpen] = useState(true);

  const filteredTabs = tabs.filter(tab => tab.open === true);

  const Chevron = () => {
    return(open ? <KeyboardArrowDownIcon onClick={handleClick} /> : <KeyboardArrowRightIcon  onClick={handleClick} />)
  } 

  const handleClick= () => {
    open ? setOpen(false) : setOpen(true);
  }

  const handleChange = (newValue) => {
    dispatch(activeTab(newValue));
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Typography 
        sx={{ 
          minWidth: 100, 
          color:'#E4E6EB',
          ml: -2,
          '&:hover': {
            cursor: 'pointer',
          } 
        }} 
        variant="subtitle2"
        onClick={handleClick}
        
      >
        {Chevron(open)} OPEN EDITORS
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
            <List>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {
                  filteredTabs?.map((tab, index) => {
                    return (
                      <ListItem 
                        key={index}
                        sx={{
                          '&:hover': {
                              background: '#B0B3B8',
                              marginLeft: -1,
                              transition: 'all 0.3s ease',
                            },
                            cursor: 'pointer',
                            ml: 1,
                          }}
                          onClick={() => handleChange(tab.id)}
                        >
                        <ListItemIcon>
                          <JavascriptIcon 
                            sx={{
                              backgroundColor: '#FCE205',
                            }}
                            fontSize='small'
                          />
                        </ListItemIcon>
                        <ListItemText 
                          primary={tab.title} 
                          sx={{ml: -3}}
                          />
                      </ListItem>
                    )
                  })
                }
                </Collapse>
            </List>
        </Grid>
      </Grid>
    </Box>
  )
}
