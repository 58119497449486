import { Typography } from '@mui/material';

export const CommitComponent = ({commit, marginLeft}) => {
  return (
    <Typography
    sx={{
      color: '#4E9258',
      whiteSpace: 'pre-wrap',
      ml: marginLeft,
    }}
  >
    {`//${commit}`}
  </Typography>
  )
}
