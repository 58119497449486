import { DataAbout } from '../data/DataAbout';
import { CommitComponent } from './shared/CommitComponent';
import { FunctionComponent } from './shared/FunctionComponent';
import { ImportComponent } from './shared/ImportComponent';
import { ReturnComponent } from './shared/ReturnComponent';
import Box from '@mui/material/Box';

export const About = () => {
  return (
    <Box>
      <ImportComponent 
        component ={'React'}
        from ={`'react'`}
      />
      <ImportComponent 
        component ={'{ Info }'}
        from ={`'daniel'`}
      />
      <FunctionComponent 
        componentName ={'About'}
        marginTop={100}
        marginLeft={0}
      >
        <ReturnComponent>
          <CommitComponent 
            commit={'Welcome and know a little about me'}
            marginLeft={20}
          />
          <DataAbout />
        </ReturnComponent>
      </FunctionComponent>
    </ Box>
  )
}
