import React from 'react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import Box from '@mui/material/Box';

export const IconButtons = () => {
  return (
    <Box 
        display="grid" 
        gridTemplateRows="repeat(12, 1fr)" 
        gap={2} 
        sx={{color:'#B0B3B8'}}
    >
        <Box gridColumn="span 2" ml={3}>
            <FileCopyOutlinedIcon 
                fontSize='large' 
                sx={{
                    '&:hover': {
                        color: '#E4E6EB'
                      },
                      cursor: 'pointer',
                }}
            />
        </Box>
        <Box gridColumn="span 2" ml={3}>
            <SearchOutlinedIcon 
                fontSize='large' 
                sx={{
                    '&:hover': {
                        color: '#E4E6EB'
                      },
                      cursor: 'pointer',
                }}
            />
        </Box>
        <Box gridColumn="span 2" ml={3}>
            <ShareOutlinedIcon 
                fontSize='large'
                sx={{
                    '&:hover': {
                        color: '#E4E6EB'
                      },
                    cursor: 'pointer',
                }}
                />
        </Box>
        <Box gridColumn="span 2" ml={3}>
            <BugReportOutlinedIcon 
                fontSize='large'
                sx={{
                    '&:hover': {
                        color: '#E4E6EB'
                      },
                    cursor: 'pointer',
                }}
                />
        </Box>
        <Box gridColumn="span 2" ml={3}>
            <ExtensionOutlinedIcon 
                fontSize='large'
                sx={{
                    '&:hover': {
                        color: '#E4E6EB'
                      },
                    cursor: 'pointer',
                }}
                />
        </Box>
        <Box gridColumn="span 2" ml={3}>
            <StorageOutlinedIcon 
                fontSize='large'
                sx={{
                    '&:hover': {
                        color: '#E4E6EB'
                      },
                    cursor: 'pointer',
                }}
                />
        </Box>
    </Box>
  )
}
