import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ImportComponent = ({component, from}) => {
  return (
    <Box
    sx={{
      width: '100wh',
      height: '4vh',
      mt:-2
    }}
  >
    <Typography
      ml={10}
      sx={{
        color: '#DA70D6',
      }}
    >
      {`import`}
    </Typography>
    <Typography
        ml={18}
        mt={-3}
        sx={{
          color: '#82CAFF',
        }}
      >
        {component}
      </Typography>
      <Typography
        ml={25}
        mt={-3}
        sx={{
          color: '#DA70D6',
        }}
      >
        {`from`}
      </Typography>
      <Typography
        ml={31}
        mt={-3}
        sx={{
          color: '#F4A460',
        }}
      >
        {from}
      </Typography>
      <Typography
        ml={37.5}
        mt={-3}
        sx={{
          color: '#FFFFFF',
        }}
      >
        {`;`}
      </Typography>
  </Box>
  )
}
