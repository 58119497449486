import React, { useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Typography
} from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { OpenEditors } from './OpenEditors';
import { ListFiles } from './ListFiles';

const options = [
  'Open Editors',
  'Folders',
  'Outline',
  'Timeline',
  'NPM Scripts',
  
];


export const MyFiles = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  
  return (
    <Box 
      display="grid" 
      gridTemplateRows="repeat(12, 1fr)" 
      gap={2} 
      sx={{
        color:'#E4E6EB', 
        backgroundColor:'#242526',
        ml:3,
        height:'115vh',
      }}
    >
      <Box gridColumn="span 4" ml={3}>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Typography 
            sx={{ minWidth: 100, color:'#E4E6EB' }} 
            
            variant="body2"
          >
            EXPLORER
          </Typography>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreHoriz sx={{color:'#E4E6EB' , ml:8}}/>
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
                background:'#242526',
                color:'#E4E6EB'
              },
            }}
          >
              {options.map((option) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                  <CheckOutlinedIcon fontSize='inherit' sx={{ml:-1, mr:1}}/> {option}
                </MenuItem>
              ))}
            </Menu>
        </Box>
        <OpenEditors />
      </Box>
      <Box gridColumn="span 4" ml={3}>
          <ListFiles />
      </Box>
    </Box>
  )
}
