import React from 'react'
import { Routes, Route } from "react-router-dom";
import { About } from './components/About';
import { Main } from './components/Main';

export const App = () => {
  return (
    <div>
      <Routes>
          <Route path="/" element={<Main />} />
          <Route path="about" element={<About />} />
      </Routes>
    </div>
  )
}
