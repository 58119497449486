import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { removeTab, activeTab } from '../actions/tabs';
import { About } from './About';
import { Breadcrumb } from './Breadcrumbs';
import { Career } from './Career';
import { Projects } from './Projects';

const TabPanel = (props)  => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography
            component={'div'}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const CodeScreen = () => {

  const dispatch = useDispatch();
  const { tabs, activeTab:active } = useSelector(state => state.tabs);

  const handleChange = (newValue) => {
    dispatch(activeTab(newValue));
  };
  
  const handleDelete = (id) => {
    dispatch(removeTab(id))
  }

 
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={active}
          onChange={handleChange}
          aria-label="my tabs"
        >
        {
          tabs.map((tab, index) => {
            return (
              <Tab 
                key={index} 
                label={tab.title}
                value={tab.id}
                onClick={() => handleChange(tab.id)}
                icon={<CloseIcon 
                    fontSize='small'
                    sx={{
                      color: 'white'
                    }}
                    onClick={() => handleDelete(tab.id)}
                />}
                iconPosition="end"
                sx={{
                  color: '#E4E6EB',
                }}
              />
            )
          })
        }
        </Tabs>
        {
          tabs.length > 0 ? <Breadcrumb /> : null
        }
      </Box>
      {
        tabs.map((tab, index) => {
          return (
            <TabPanel
              key={index}
              value={active}
              index={tab.id}
            >
              {tab.content === 'about' ?
                <About /> : tab.content === 'career' ? <Career /> : <Projects />
              }
            </TabPanel>
          )
        })
      }
    </Box>
  )
}
