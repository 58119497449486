import React from 'react'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelector } from 'react-redux';

export const Breadcrumb = () => {
  const { tabs, activeTab } = useSelector(state => state.tabs);

  const getNameFile = (tabs, active) => {
    return (
      tabs?.map((tab) => {
        return tab.id === active && tab.path
      })
    )
  }

  return (
    <Box 
      mt={1}
      ml={2}
    >
    <Breadcrumbs 
      aria-label="breadcrumb" 
      sx={{
        color:'#B0B3B8'
      }}
      separator={<NavigateNextIcon fontSize="small" />}
    >
        <Typography color="#B0B3B8">src</Typography>
        <Typography color="#B0B3B8">components</Typography>
        <Typography color="#B0B3B8">{getNameFile(tabs, activeTab)}</Typography>
    </Breadcrumbs>
  </Box>
  )
}
