import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import AndroidIcon from '@mui/icons-material/Android';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Typography from '@mui/material/Typography';

export const DataCareer = () => {
  return (
    <Timeline 
        position="alternate" 
        sx={{maxWidth: 345, ml:20}}
    >
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="white"
        >
          Jul 2020 - Nov 2021
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot  color="secondary">
            <AndroidIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2, color:"white" }}>
          <Typography variant="h6" component="span">
            Catalyst Technologies
          </Typography>
          <Typography>Fullstack developer SR</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="white"
        >
         Dec 2018 - Jan 2020
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2, color:"white" }}>
          <Typography variant="h6" component="span">
            VanillaSys
          </Typography>
          <Typography>Fullstack developer</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="white"
        >
          Jan 2017 - Dec 2018
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <ArrowCircleUpIcon />
          </TimelineDot>
          <TimelineConnector  />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2, color:"white" }}>
          <Typography variant="h6" component="span">
            Indexceed
          </Typography>
          <Typography>Web developer</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector  />
          <TimelineDot color="secondary">
            <PlayCircleFilledIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2, color:"white" }}>
          <Typography variant="h6" component="span">
            2016
          </Typography>
          <Typography>I started my professional career!</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )
}
